import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_CUSTOM_URL } from '@wix/communities-blog-experiments';
import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import { get } from 'lodash';
import { CUSTOM_ROUTE_PLACEHOLDERS } from '../../constants/custom-route-placeholders';

const generateCustomRoute = ({ state, key, placeholder, value }) => {
  if (!isExperimentEnabled(state, EXPERIMENT_CUSTOM_URL)) {
    return '';
  }

  const customPostRoute = get(getCustomRoutes(state), key);
  if (!customPostRoute) {
    return '';
  }

  return customPostRoute.replace(placeholder, value);
};

export const getCustomRoutes = (state) => get(state, 'customRoutes', null);

export const getCustomPostUrl = (state, slug) => {
  return generateCustomRoute({
    state,
    key: UrlMappingsKeys.BLOG_POST,
    placeholder: CUSTOM_ROUTE_PLACEHOLDERS.postSlug,
    value: slug,
  });
};

export const getCustomPostEditUrl = (state, id) => {
  return generateCustomRoute({
    state,
    key: UrlMappingsKeys.BLOG_POST_EDIT,
    placeholder: CUSTOM_ROUTE_PLACEHOLDERS.postId,
    value: id,
  });
};

import { omit, trimStart } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { SECTION_BLOG_PAGE, SECTION_BLOG_POST_PAGE, isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_PROD_OOI_EDITOR } from '@wix/communities-blog-experiments';

import { connect } from '../runtime-context';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import { isAppRoute } from '../../services/section-route-utils';
import { getInternalLinkRoute } from '../../services/get-internal-link-route';
import Wix from '../../services/wix-sdk-polyfill';

const OMIT_PROPS = ['dispatch', 'titleFontClassName', 'tReady', 'lng', 'contentFontClassNameWithStyle'];

const InternalLink = ({
  addHoverClasses,
  children,
  className,
  isSite,
  sectionId,
  to,
  topology,
  useCustomRouter,
  navigateInPreview,
  fullRoute,
  isExperimentProdOOIEditorEnabled,
  ...props
}) => {
  const shouldNavigateToFeed = sectionId === SECTION_BLOG_PAGE || isAppRoute(to);
  const route = fullRoute || getInternalLinkRoute({ topology, to, shouldNavigateToFeed });
  const linkClassName = classNames(addHoverClasses && 'blog-link-hover-color', className);
  const linkProps = omit(props, OMIT_PROPS);

  const handleClick = (event) => {
    if (!isSite) {
      event.preventDefault();
      if (isExperimentProdOOIEditorEnabled) {
        Wix.Utils.navigateToSection(
          {
            appDefinitionId: BLOG_APP_ID,
            sectionId: shouldNavigateToFeed ? SECTION_BLOG_PAGE : SECTION_BLOG_POST_PAGE,
            shouldRefreshIframe: false,
          },
          trimStart(route, '/'),
        );
      } else {
        navigateInPreview(route);
      }
    }
  };

  return (
    <a href={route} onClick={handleClick} className={linkClassName} {...linkProps}>
      {children}
    </a>
  );
};

InternalLink.propTypes = {
  push: PropTypes.func,
  to: PropTypes.string,
  children: PropTypes.node,
  addHoverClasses: PropTypes.bool,
  topology: PropTypes.object,
  isSite: PropTypes.bool,
  sectionId: PropTypes.string,
  navigateInPreview: PropTypes.func.isRequired,
  isExperimentProdOOIEditorEnabled: PropTypes.bool,
};

InternalLink.defaultProps = {
  addHoverClasses: true,
};

const mapRuntimeToProps = (state, _ownProps, actions) => ({
  topology: getTopology(state),
  isSite: isSite(state),
  navigateInPreview: actions.navigateInPreview,
  isExperimentProdOOIEditorEnabled: isExperimentEnabled(state, EXPERIMENT_PROD_OOI_EDITOR),
});

export default connect(mapRuntimeToProps)(InternalLink);
